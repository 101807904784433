<template>
<div>
<div class="control-section">
    <div class="content-wrapper">
        <ejs-gantt ref='gantt' id="GanttContainer"  
            :dataSource= "dataSource"
            :dateFormat= "dateFormat"
            :allowSelection= "true"
            :gridLines= "gridLines"
            :height= "height"
            :resourceFields= "resourceFields"
            :resources= "resources"
            :highlightWeekends= "true"
            :timelineSettings= "timelineSettings"
            :eventMarkers= "eventMarkers"
            :editDialogFields= "editDialogFields"
            :allowRowDragAndDrop= "true"
            :allowPdfExport= "true"
            :allowExcelExport= "true"
            :selectionSettings= "selectionSettings"
            :allowReordering= 'true'
            :allowSorting= 'true'
            :allowResizing= 'true'
            :enableContextMenu= 'true'
            :showColumnMenu= 'true'        
            :taskFields= "taskFields"
            :columns= "columns"
            :labelSettings= "labelSettings"
            :treeColumnIndex= "1"
            :toolbar= "toolbar"
            :editSettings= "editSettings"
            :projectStartDate= "projectStartDate"
            :projectEndDate= "projectEndDate"
            :created= "created"
            :load= "load"
            :dataBound= "dataBound"
            :toolbarClick= "toolbarClick"
            :beforeTooltipRender= "beforeTooltipRender"
            :actionBegin= "actionBegin"
            :actionComplete= "actionComplete"
            :cellEdit= "cellEdit"
            :endEdit= "endEdit"
            :taskbarEditing= "taskbarEditing"
            :taskbarEdited= "taskbarEdited"
            :rowSelecting= "rowSelecting"
            :rowSelected= "rowSelected"
            :rowDeselecting= "rowDeselecting"
            :rowDeselected= "rowDeselected"
            :columnDragStart= "columnDragStart"
            :columnDrag= "columnDrag"
            :columnDrop= "columnDrop"
            :expanding= "expanding"
            :expanded= "expanded"
            :collapsing= "collapsing"
            :collapsed= "collapsed"
            :columnMenuClick= "columnMenuClick"
            :columnMenuOpen= "columnMenuOpen"
            :contextMenuClick= "contextMenuClick"
            :contextMenuOpen= "contextMenuOpen"
            :resizeStart= "resizeStart"
            :resizing= "resizing"
            :resizeStop= "resizeStop"
            :splitterResizeStart= "splitterResizeStart"
            :splitterResizing= "splitterResizing"
            :splitterResized= "splitterResized"
            :recordDoubleClick= "recordDoubleClick"
            :onTaskbarClick= "onTaskbarClick"
            :includeWeekend= "true"

        >
        </ejs-gantt>
    </div>
</div>


</div>
</template>
<script>
import Vue from "vue";
import { GanttPlugin} from "@syncfusion/ej2-vue-gantt";
import {Edit, Selection,PdfExport,ExcelExport, Toolbar, DayMarkers,  RowDD} from "@syncfusion/ej2-vue-gantt";
import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
Vue.use(GanttPlugin);

// import { DataManager, UrlAdaptor, WebApiAdaptor, UrlAdaptor } from '@syncfusion/ej2-data';
// import { splitTasksData } from '../services/GanntDataService';

            
// const TasksResourceData = new DataManager({
//     url:baseURL+'gantt-resources-data',
//     adaptor: new WebApiAdaptor,
//     crossDomain: true
// });
 const splitTasksData = new DataManager({
                // url: 'https://wonpager-project.onrender.com/production/web-services/api/GanttData',
                url: 'https://app.wonpager.com/gantt/gantt_demo/GanttData.php',

                adaptor: new WebApiAdaptor,
                crossDomain: true
            }); 
export default Vue.extend({
  data: function() {  
      return{
            dataSource: splitTasksData,
            taskFields: {
                id: 'TaskId',
                name: 'TaskName',
                startDate: 'StartDate',
                progress: 'Progress',
                duration: 'Duration',
                dependency: 'Predecessor',
                child: 'SubTasks',
                segments: 'Segments',
                resourceInfo: 'resources'
            },
            columns: [
                { field: 'TaskId', visible: false, headerText: 'ID', width: 60 },
                { field: 'TaskName', headerText: 'Développement des affaires 2022', width: '250', clipMode: 'EllipsisWithTooltip' },
                { field: 'StartDate' },
                { field: 'Duration' },
                { field: 'Progress' },
                { field: 'Predecessor' }
            ],
            toolbar: ['Edit', 'Update', 'Delete', 'Cancel', 'ExpandAll', 'CollapseAll', 'Search', 'ZoomIn', 'ZoomOut', 'ZoomToFit', 'PdfExport', 'ExcelExport'],
            
            height: 'auto',
            editSettings: {
                allowAdding: true,
                allowEditing: true,
                allowDeleting: true,
                allowTaskbarEditing: true,
                showDeleteConfirmDialog: true
            },
            labelSettings: {
            leftLabel: 'TaskId',
            rightLabel: 'TaskName',
            taskLabel: '${Progress}%',
            },
            // projectStartDate: new Date('03/31/2021'),
resourceFields: {
        id: 'resourceId',
        name: 'resourceName',
        unit: 'Unit',
        group: 'resourceGroup'
    },
// resources: TasksResourceData,  
 resources: [
            { resourceId: 1, resourceName: 'Martin Tamer' },
            { resourceId: 2, resourceName: 'Rose Fuller' },
            { resourceId: 3, resourceName: 'Margaret Buchanan' },
            { resourceId: 4, resourceName: 'Fuller King' },
            { resourceId: 5, resourceName: 'Davolio Fuller' },
            { resourceId: 6, resourceName: 'Van Jack' },
            { resourceId: 7, resourceName: 'Fuller Buchanan' },
            { resourceId: 8, resourceName: 'Jack Davolio' },
            { resourceId: 9, resourceName: 'Tamer Vinet' },
            { resourceId: 10, resourceName: 'Vinet Fuller' },
            { resourceId: 11, resourceName: 'Bergs Anton' },
            { resourceId: 12, resourceName: 'Construction Supervisor' }
        ],
            // projectEndDate: new Date('03/31/2022'),
            projectStartDate: new Date('02/24/2019'),
            projectEndDate: new Date('06/10/2019'),
            splitterSettings: {
                columnIndex: 10
            },
            gridLines: 'Both',
            timelineSettings: {
                timelineUnitSize: 50,
                topTier: {
                    unit: 'Month',
                    format: 'MMM dd, y',
                },
                bottomTier: {
                    unit: 'Day',
                    formatter: (date) => {
                        let month = date.getMonth();
                        if (month === 1) {
                            return '';
                        } else {
                            let presentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                            let start = new Date(presentDate.getFullYear(), 0, 0);
                            let diff = Number(presentDate) - Number(start);
                            let oneDay = 1000 * 60 * 60 * 24;
                            let day = Math.floor(diff / oneDay);
                            return 'day ' + (day - 59);
                        }
                    },
                },
            },
      };
  },
  provide:  {
      gantt: [Edit,RowDD, Selection, Toolbar, DayMarkers, PdfExport, ExcelExport]
  }
});
</script>